import React from 'react';
import { Button, EmptyState } from '@user-interviews/ui-design-system';

import * as routes from 'lib/routes';
import InteractingWithInterface from 'images/interacting_with_interface.png';

export const subtitle = {
  recruitProject: 'The study you were looking for is closed. Explore other active studies to participate.',
  hubProject: 'The study you were looking for is now closed.' +
    ' Be sure to keep an eye out for more opportunities in the future.',
}

export type StudyClosedProps = {
  isRecruitProject: boolean;
}

export function StudyClosed({ isRecruitProject }: StudyClosedProps) {
  return (
    <EmptyState
      image={InteractingWithInterface}
      primaryAction={(
        isRecruitProject ?
          (
            <a href={routes.browse_projects_path()}>
              <Button variant="primary">
                Explore other studies
              </Button>
            </a>
          ) :
          null
      )}
      subtitle={isRecruitProject ? subtitle.recruitProject : subtitle.hubProject}
      title="This study has closed"
    />
  )
}
